import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import LoadingComponent from '../core/componentLoading/loading';
import { FormValues, PrefillContext } from '../core/contexte/prefillContext';
import SaveAndRetrieveProvider from '../core/contexte/saveAndRetrieveContext';

import api from '../api';
import { constants } from '../constants';
import {
  AMEXKYCERRORS,
  AMEXKYCVALIDATION,
  VISA_SteuerlandNichtD1_ERRORS,
  VISA_VALIDATION,
} from '../constants/amex_complexValidations';
import LazyComponentLoader from '../core/componentLoading/lazyComponentLoader';
import GenericErrorPage from '../core/genericErrorPage';
import { GlobalComponentLoader } from '../core/services/componentLoader';
import { FormPage } from './formPage';
import ComponentBuilder from '../core/formBuilder/componentBuilder';
import FormularElement from '../core/formBuilder/formularElement';

interface ProjectPageProps {
  setTheme: Dispatch<SetStateAction<{}>>;
}

export const ProjectPage: FunctionComponent<ProjectPageProps> = ({
  setTheme,
}) => {
  const { project } = useParams();
  const { search } = useLocation();
  const { setDefaultValues } = useContext(PrefillContext);
  const [config, setConfig] = useState({
    form: {},
    intl: null,
    meta: {},
    dataSchema: {
      errorSchema: {},
      validationSchema: {},
    },
    technicalComponents: undefined,
    theme_config: undefined,
    staticPagesConfig: [],
    defaultValues: [],
  });

  const urlSearchParams = new URLSearchParams(search);

  const renderCustomStaticPages = () => {
    // TODO should be replaced by global static pages
    return (
      constants.PATH_FOR_CUSTOM_STATIC_PAGES && (
        <LazyComponentLoader
          key={'CustomStaticPages'}
          excludeGrid={true}
          refComponent={`asb_modules/${constants.PATH_FOR_CUSTOM_STATIC_PAGES}/CustomStaticRoutes`}
        />
      )
    );
  };

  const renderFillIndexHTML = () => {
    // TODO should be replaced by global technical components
    return (
      constants.PATH_FOR_CUSTOM_STATIC_PAGES && (
        <LazyComponentLoader
          key={'FillIndexHTML'}
          excludeGrid={true}
          refComponent={`asb_modules/${constants.PATH_FOR_CUSTOM_STATIC_PAGES}/indexHTML/FillIndexHTML`}
        />
      )
    );
  };

  const renderLazy = (page: FormularElement) => {
    return <ComponentBuilder element={page} />;
  };

  const fillConfig = (data: any) => {
    if (data.theme_config) setTheme(data.theme_config);
    // must be the name of the current project
    if (project === 'amex' || project === 'amexVers') {
      addAmexKYCValidation(data);
      addAmexKYCErrors(data);
    } else if (project === 'visa') {
      addVISA_VALIDATION(data);
      addVISA_SteuerlandNichtD1_ERRORS(data);
    }
    setDefaultValues(
      data?.defaultValues?.reduce(
        (
          acc: FormValues,
          defaultValue: {
            fieldName: string;
            value: string;
          }
        ) => ({ ...acc, [defaultValue.fieldName]: defaultValue.value }),
        {}
      )
    );
    setConfig(data);
  };

  useEffect(() => {
    if (JSON.stringify(config.form) === '{}') {
      // TODO: fix this
      if (urlSearchParams.has('version'))
        api.Public.getConfigByVersion(project, urlSearchParams.get('version'))
          .then((data) => fillConfig(data))
          .catch((error) => console.warn(error));
      else
        api.Public.getActiveConfigByProjectName(project)
          .then((data) => fillConfig(data))
          .catch((error) => console.warn(error));
    }
  }, []);

  return (
    <SaveAndRetrieveProvider>
      <Suspense fallback={<LoadingComponent />}>
        <GlobalComponentLoader
          globalComponentConfig={config.technicalComponents}
        />
        {renderFillIndexHTML()}
      </Suspense>
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route path={'error'} element={<GenericErrorPage />} />

          {config?.staticPagesConfig
            ?.filter((page: FormularElement) => page.route)
            .map((page: FormularElement) => (
              <Route path={page.route + '/*'} element={renderLazy(page)} />
            ))}

          <Route
            path={'/static/*'}
            element={renderCustomStaticPages() ?? <></>}
          />
          <Route path="*" element={<FormPage config={config} />} />
        </Routes>
      </Suspense>
    </SaveAndRetrieveProvider>
  );
};

function addAmexKYCValidation(data: any) {
  data.dataSchema.validationSchema = {
    ...data.dataSchema.validationSchema,
    allOf: [
      ...(data?.dataSchema?.validationSchema?.allOf ?? []),
      ...AMEXKYCVALIDATION,
    ],
  };
}

function addAmexKYCErrors(data: any) {
  data.dataSchema.errorSchema = {
    ...data.dataSchema.errorSchema,
    ...AMEXKYCERRORS,
  };
}

function addVISA_VALIDATION(data: any) {
  data.dataSchema.validationSchema = {
    ...data.dataSchema.validationSchema,
    allOf: [
      ...(data?.dataSchema?.validationSchema?.allOf ?? []),
      ...VISA_VALIDATION,
    ],
  };
}

function addVISA_SteuerlandNichtD1_ERRORS(data: any) {
  data.dataSchema.errorSchema = {
    ...data.dataSchema.errorSchema,
    ...VISA_SteuerlandNichtD1_ERRORS,
  };
}
